@import url('../variables.css');

.terms {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  text-align: center;
  margin-bottom: 3rem;
}

.hero h1 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h1-size);
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.hero h2 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h2-size);
  color: var(--secondary-color);
}

.content h3 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h3-size);
  color: var(--primary-color);
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.content p,
.content ul {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.content ul {
  padding-left: 1.5rem;
}

.link {
  color: var(--accent-color);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
