@import url('../variables.css');
/* Global Styles */
.faq-container {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color); /* Neutral Light */
  color: var(--text-color); /* Neutral Dark */
  padding: 2rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

/* Hero Section */
.hero {
  background-color: var(--background-color); /* Primary Color – Deep Brown */
  color: var(--light-text-color); /* Neutral Light */
  text-align: center;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;
}

.hero h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.hero h2 {
  font-size: 1.2rem;
  font-weight: normal;
}

.faq-list {
  margin-bottom: 3rem;
}

.faq-item {
  border-bottom: 1px solid var(--secondary-color);
  padding: 1rem 0;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.faq-question h3 {
  font-family: 'Roboto Slab', serif;
  font-size: 1.5rem;
  color: var(--primary-color); /* Primary Color – Deep Brown */
  margin: 0;
}

.faq-question .arrow {
  width: 12px;
  height: 12px;
  border-right: 2px solid var(--light-text-color); /* Neutral Light */
  border-bottom: 2px solid var(--light-text-color); /* Neutral Light */
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.faq-question .arrow.open {
  transform: rotate(-135deg);
}

.faq-answer {
  margin-top: 0.5rem;
  font-size: 1rem;
  color: var(--text-color); /* Neutral Dark */
  line-height: 1.6;
}

.faq-answer ul {
  list-style-type: disc;
  margin-left: 1.5rem;
}

.faq-answer p {
  margin-bottom: 1rem;
}

.faq-answer strong {
  color: var(--primary-color); /* Primary Color */
}

.contact-info {
  background-color: var(--background-color);
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
}

.contact-info h3 {
  font-family: 'Roboto Slab', serif;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.contact-info p {
  font-size: 1rem;
  color: var(--text-color);
  margin-bottom: 0.5rem;
}


.contact-info a:hover {
  text-decoration: underline;
}

.contact-link {
  color: var(--accent-color);
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.cta-button a {
  color: var(--light-text-color);
  text-decoration: none;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color); /* Accent Color – Crimson Red */
  color: var(--light-text-color); /* Neutral Light */
  padding: 0.8rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2rem;
  }

  .faq-question h3 {
    font-size: 1.3rem;
  }
}
