@import url('variables.css');

.app-container {
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--body-font);
  background-color: var(--background-color);
  color: var(--text-color);
}

* {
  box-sizing: border-box;
}

.website-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cookie-consent {
  background: var(--primary-color) !important;
  color: var(--light-text-color) !important;
  font-family: var(--body-font) !important;
  font-size: var(--body-size) !important;
  padding: var(--spacing-medium) !important;
}

.cookie-consent-button {
  background: var(--accent-color) !important;
  color: var(--light-text-color) !important;
  font-size: var(--body-size) !important;
  font-family: var(--body-font) !important;
  padding: var(--spacing-small) var(--spacing-medium) !important;
  border-radius: var(--border-radius) !important;
}

.cookie-consent-button:hover {
  background: var(--accent-color-hover) !important;
}


header {
  background-color: var(--background-color);
  padding: var(--spacing-medium);
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
}

nav ul li a {
  text-decoration: none;
  color: var(--secondary-color);
  transition: color 0.3s;
}

nav ul li a:hover {
  color: var(--primary-color);
}

main {
  flex: 1;
  padding: var(--spacing-large);
}

footer {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  padding: var(--spacing-medium);
  text-align: center;
}