/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Roboto+Slab:wght@400;700&display=swap');

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.step-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #BDBDBD; /* Inactive Step Color */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.step-circle.active {
  background-color: #D32F2F; /* Active Step Color */
}

.step-number {
  color: #FAFAFA; /* Text Color */
  font-family: 'Roboto Slab', serif;
  font-size: 1rem;
}

.step-line {
  position: absolute;
  top: 15px;
  left: 50%;
  right: -50%;
  height: 2px;
  background-color: #BDBDBD; /* Inactive Line Color */
  z-index: 0;
}

.step-line.active {
  background-color: #D32F2F; /* Active Line Color */
}

.step-label {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.9rem;
  color: #757575;
  font-family: 'Open Sans', sans-serif;
  width: 80px;
}

@media (max-width: 768px) {
  .progress-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .progress-step {
    flex-direction: row;
    margin-bottom: 1rem;
  }

  .step-label {
    margin-top: 0;
    margin-left: 0.5rem;
    text-align: left;
  }

  .step-line {
    display: none;
  }
}
