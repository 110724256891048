@import url('../variables.css');

.website-layout {
  font-family: var(--body-font);
  background-color: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.site-header {
  background-color: var(--background-color);
  padding: var(--spacing-medium) var(--spacing-large);
  border-bottom: 1px solid var(--primary-color);
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  flex-shrink: 0;
}

.logo {
  height: 50px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
}

.nav-links li {
  font-family: var(--heading-font);
}

.nav-links a {
  color: var(--secondary-color);
  text-decoration: none;
  font-size: var(--body-size);
  transition: color 0.3s, border-bottom 0.3s;
  padding: var(--spacing-small) 0;
  border-bottom: 2px solid transparent;
}

.nav-links a:hover,
.nav-links a.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

main {
  flex: 1;
  padding: var(--spacing-large);
}

.site-footer {
  background-color: var(--primary-color);
  color: var(--light-text-color);
  text-align: center;
  padding: var(--spacing-medium) var(--spacing-large);
  font-size: var(--small-size);
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.hamburger-menu span {
  width: 2rem;
  height: 0.25rem;
  background: var(--primary-color);
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .navigation {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .nav-links {
    position: fixed;
    right: -100%;
    top: 0;
    flex-direction: column;
    background-color: var(--background-color);
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    border-top: 1px solid var(--primary-color);
    transition: all 0.5s;
    z-index: 9;
    padding-top: 5rem;
    
  }

  .nav-links.open {
    right: 0;
  }

  .nav-links.open + main {
    position: fixed;
    width: 100%;
  }

  .nav-links li {
    margin: 0.25rem 0;
  }

  .nav-links a {
    font-size: 1.2rem;
  }

  .logo {
    height: 40px;
  }
}
