.text-position-control {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
  
  .horizontal-controls {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .control-button {
    width: 40px;
    height: 40px;
    margin: 5px;
    font-size: 1.2rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .control-button:hover {
    background-color: #e0e0e0;
  }
  
  .control-button:active {
    background-color: #d0d0d0;
  }