@import url('../variables.css');

.home {
  font-family: var(--body-font);
  background-color: var(--background-color);
  color: var(--text-color);
}

.hero {
  width: 100%;
  background-color: var(--secondary-color);
  position: relative;
  overflow: hidden;
}

.hero-content {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 600px;
}

.hero-text {
  flex: 1;
  padding: var(--spacing-large);
  color: var(--light-text-color);
  z-index: 2;
}

.hero-title {
  font-family: var(--heading-font);
  font-size: var(--h1-size);
  margin-bottom: var(--spacing-medium);
}

.hero-subtitle {
  font-size: var(--body-size);
  margin-bottom: var(--spacing-large);
}

.hero-cta {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: var(--spacing-medium) var(--spacing-large);
  border: none;
  font-size: var(--body-size);
  cursor: pointer;
  font-family: var(--body-font);
  transition: background-color 0.3s;
}

.hero-cta:hover {
  background-color: #B71C1C;
}

.hero-video-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.hero-video {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.how-it-works,
.featured-products,
.testimonials {
  padding: var(--spacing-large);
}

h2 {
  font-family: var(--heading-font);
  font-size: var(--h2-size);
  color: var(--primary-color);
  text-align: center;
  margin-bottom: var(--spacing-large);
}

.steps,
.product-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.step,
.product-item {
  flex: 1 1 300px;
  max-width: 300px;
  margin: var(--spacing-medium);
  text-align: center;
}

.step h3,
.product-item h3 {
  font-family: var(--heading-font);
  font-size: var(--h3-size);
  color: var(--secondary-color);
  margin-bottom: var(--spacing-medium);
}

.step p,
.product-item p {
  font-size: var(--body-size);
  color: var(--text-color);
}

.section-cta {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  font-size: var(--body-size);
  cursor: pointer;
  font-family: var(--body-font);
  transition: background-color 0.3s;
  display: block;
  margin: var(--spacing-large) auto 0;
}

.section-cta:hover {
  background-color: #B71C1C;
}

.featured-products {
  background-color: #F5F5F5;
}

.product-item img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

.testimonial-item {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.testimonial-item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: var(--spacing-medium);
}

.testimonial-item p {
  font-size: var(--body-size);
  font-style: italic;
  color: var(--text-color);
  margin-bottom: var(--spacing-medium);
}

.testimonial-item h4 {
  font-size: var(--body-size);
  color: var(--secondary-color);
}

@media (max-width: 768px) {
  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    padding: var(--spacing-large) var(--spacing-medium);
  }

  .hero-title {
    font-size: var(--h2-size);
  }

  .hero-subtitle {
    font-size: var(--body-size);
  }

  .hero-cta {
    font-size: var(--body-size);
    padding: var(--spacing-small) var(--spacing-medium);
  }

  .hero-video-container::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  .steps,
  .product-gallery {
    flex-direction: column;
    align-items: center;
  }

  .step,
  .product-item {
    max-width: none;
  }
}

.process-gif {
  width: 100%;
  max-width: 800px;
  margin: 2rem auto;
  text-align: center;
}

.process-gif img {
  width: 50%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}