@import url('../variables.css');

.contact-container {
  max-width: 600px;
  margin: 0 auto;
  padding: var(--spacing-large);
  background-color: var(--background-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-container h2 {
  font-family: 'Roboto Slab', serif;
  color: var(--primary-color);
  margin-bottom: var(--spacing-medium);
}

.contact-container p {
  font-family: 'Open Sans', sans-serif;
  color: var(--text-color);
  margin-bottom: var(--spacing-large);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: var(--spacing-medium);
}

.form-group label {
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  margin-bottom: var(--spacing-small);
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.form-group textarea {
  min-height: 150px;
  resize: vertical;
}

.submit-btn {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  border: none;
  padding: 12px 20px;
  font-family: 'Roboto Slab', serif;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #b71c1c;
}

.submit-btn:disabled {
  background-color: #757575;
  cursor: not-allowed;
}

.success-message,
.error-message {
  margin-top: var(--spacing-medium);
  padding: 10px;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
}

.success-message {
  background-color: #4CAF50;
  color: white;
}

.error-message {
  background-color: #F44336;
  color: white;
}