:root {
    /* Color Palette */
    --primary-color: #000000;
    --secondary-color: #757575;
    --accent-color: #D32F2F;
    --accent-color-hover: #b42828;  
    --background-color: #FAFAFA;
    --text-color: #000000;
    --light-text-color: #FAFAFA;
  
    /* Typography */
    --heading-font: 'Roboto Slab', serif;
    --body-font: 'Open Sans', sans-serif;
  
    /* Font Sizes */
    --h1-size: 2.5rem;
    --h2-size: 2rem;
    --h3-size: 1.5rem;
    --body-size: 1rem;
    --small-size: 0.9rem;
  
    /* Spacing */
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 2rem;
  
    /* Border Radius */
    --border-radius: 4px;
  }