@import url('../variables.css');

.about {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero {
  text-align: center;
  margin-bottom: 3rem;
  background-color: var(--background-color);
  color: var(--light-text-color);
}

.hero h1 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h1-size);
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.hero h2 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h2-size);
  color: var(--secondary-color);
}

section {
  margin-bottom: 3rem;
}

h3 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h3-size);
  color: var(--primary-color);
  margin-bottom: 1rem;
}

h4 {
  font-family: 'Roboto Slab', serif;
  font-size: var(--h4-size);
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.how-it-works ol {
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.how-it-works li {
  margin-bottom: 0.5rem;
}

.cta-button {
  display: inline-block;
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: 1rem 2rem;
  text-decoration: none;
  font-weight: bold;
  border-radius: var(--border-radius);
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: var(--accent-color);
}

@media (max-width: 768px) {
  .about {
    padding: 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .hero h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.8rem;
  }
}