@import url('../variables.css');

@font-face {
  font-family: 'Ferrari';
  src: url('../assets/fonts/ferrari.ttf') format('truetype');
}

@font-face {
  font-family: 'Porsche';
  src: url('../assets/fonts/porsche.ttf') format('truetype');
}

@font-face {
  font-family: 'Lambo';
  src: url('../assets/fonts/lambo.ttf') format('truetype');
}

@font-face {
  font-family: 'Honda';
  src: url('../assets/fonts/honda.ttf') format('truetype');
}

@font-face {
  font-family: 'Ford';
  src: url('../assets/fonts/ford.ttf') format('truetype');
}

@font-face {
  font-family: 'Mercedes';
  src: url('../assets/fonts/mercedes.ttf') format('truetype');
}

/* Apply custom fonts to select options */
.font-ferrari { font-family: 'Ferrari', sans-serif; }
.font-porsche { font-family: 'Porsche', sans-serif; }
.font-lambo { font-family: 'Lambo', sans-serif; }
.font-honda { font-family: 'Honda', sans-serif; }
.font-ford { font-family: 'Ford', sans-serif; }
.font-mercedes { font-family: 'Mercedes', sans-serif; }

.edit-output-container {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0.5rem;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}

h1,
h2 {
  font-family: 'Roboto Slab', serif;
  color: var(--primary-color);
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.image-preview {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
  background-color: var(--background-color);
  padding: var(--spacing-medium);
}

.svg-wrapper {
  width: 100%;
  position: relative;
}

.step-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.step-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.step-content h2 {
  font-size: 1.8rem;
}

.step-content p {
  font-size: 1rem;
  color: var(--secondary-color);
}

label {
  font-weight: bold;
  color: var(--primary-color);
  margin-top: var(--spacing-medium);
}

input[type='range'],
input[type='text'],
select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  font-family: 'Open Sans', sans-serif;
}

input[type='range'] {
  accent-color: var(--accent-color);
}

button {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: var(--spacing-small) var(--spacing-medium);
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  font-size: var(--body-size);
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  transition: background-color 0.3s;
  margin-top: var(--spacing-medium);
}

button:hover {
  background-color: var(--accent-color);
}

.loading-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  color: var(--secondary-color);
  font-size: 1.2rem;
  text-align: center;
  width: 100%;
  /* The height is set dynamically via inline styles */
}

@media (max-width: 768px) {
  .content-wrapper {
    padding: 1rem;
  }

  .image-preview,
  .step-wrapper {
    width: 100%;
  }
}

/* Progress Bar Styles */
.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-medium);
}

.progress-bar .step {
  flex: 1;
  position: relative;
  text-align: center;
  font-size: var(--small-size);
}

.progress-bar .step:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: var(--secondary-color);
  border-radius: 50%;
}

.progress-bar .step.active:before {
  background-color: var(--accent-color);
}

.progress-bar .line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--secondary-color);
  z-index: -1;
}

.progress-bar .step:first-child .line {
  display: none;
}

.progress-bar .step.active .line {
  background-color: var(--accent-color);
}

/* Font Preview in Select Options */
select option {
  font-family: 'Open Sans', sans-serif;
}

.font-ferrari {
  font-family: 'Ferrari', sans-serif;
}

/* Additional font styles for other fonts */

/* TextPositionControl Styles */
.text-position-control {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.text-position-control button {
  background-color: var(--secondary-color);
  color: var(--light-text-color);
  border: none;
  padding: var(--spacing-small);
  margin: 0 var(--spacing-small);
  border-radius: var(--border-radius);
  cursor: pointer;
}

.text-position-control button:hover {
  background-color: var(--secondary-color);
}

.price-display {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-color);
  margin-top: var(--spacing-medium);
  text-align: center;
  padding: var(--spacing-small);
  background-color: var(--background-color);
  border: 2px solid var(--accent-color);
  border-radius: var(--border-radius);
}

.contrast-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--spacing-medium);
}

.contrast-banner span {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--text-color);
}

.coupon-input {
  margin-top: var(--spacing-medium);
}

.coupon-input input {
  margin-top: var(--spacing-small);
  padding: 8px 12px;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  width: 100%;
  font-size: var(--body-size);
}

.coupon-input input::placeholder {
  color: var(--secondary-color);
}

/* Add these styles for the coupon input group */
.coupon-input-group {
  display: flex;
  gap: 10px;
  margin-top: var(--spacing-small);
}

.coupon-input-group input {
  flex: 1;
}

.coupon-input-group button {
  margin-top: 0;
  white-space: nowrap;
}

.coupon-input-group button:disabled {
  background-color: var(--secondary-color);
  cursor: not-allowed;
}