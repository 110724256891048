@import url('../variables.css');

.upload-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
}

.upload-container {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color); /* Neutral Light */
  color: var(--text-color); /* Neutral Dark */
  padding: 4rem 2rem;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
}

.video-container {
  width: 100%;
  max-width: 800px;
}

.video-container iframe {
  width: 100%;
  height: 450px;
  border: none;
  border-radius: var(--border-radius);
}

.upload-container h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 2.5rem;
  color: var(--primary-color); /* Primary Color – Deep Brown */
  margin-bottom: 1rem;
}

.upload-container p {
  font-size: 1.1rem;
  color: var(--secondary-color); /* Secondary Color – Metallic Gray */
  margin-bottom: 2rem;
  line-height: 1.6;
}

.file-input-container {
  margin-bottom: 1rem;
  position: relative;
  display: inline-block;
}

.file-input {
  display: none;
}

.file-input-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color); /* Accent Color – Crimson Red */
  color: var(--light-text-color); /* Neutral Light */
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: background-color 0.3s;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
}

.file-input-label:hover {
  background-color: var(--accent-color);
}

.upload-icon {
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
}

.upload-hint {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 1rem;
}

.upload-tips {
  font-size: 0.9rem;
  color: var(--secondary-color);
  margin-top: 0.5rem;
}

.upload-tips a {
  color: var(--accent-color);
  text-decoration: none;
}

.upload-tips a:hover {
  text-decoration: underline;
}

.agreement-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
}

.agreement-label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: var(--secondary-color);
}

.agreement-label input {
  margin-right: 0.5rem;
}

.agreement-label a {
  color: var(--accent-color);
  text-decoration: none;
}

.agreement-label a:hover {
  text-decoration: underline;
}

.upload-button {
  background-color: var(--accent-color);
  color: var(--light-text-color);
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s, opacity 0.3s;
}

.upload-button:hover:not(:disabled) {
  background-color: var(--accent-color-hover);
}

.upload-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.image-preview {
  position: relative;
  max-width: 100%;
  margin-bottom: 2rem;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
}

.image-preview.scanning::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.scan-line {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: var(--accent-color);
  z-index: 2;
  animation: scan 3s linear infinite;
}

@keyframes scan {
  0% {
    top: 0;
  }
  100% {
    top: 100%;
  }
}

@media (max-width: 1200px) {
  .upload-page {
    flex-direction: column;
    align-items: center;
  }

  .video-container iframe {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .upload-page {
    padding: 1rem;
    gap: 1rem;
  }

  .upload-container {
    padding: 2rem 1rem;
  }

  .upload-container h1 {
    font-size: 2rem;
  }

  .upload-container p {
    font-size: 1rem;
  }

  .file-input-label {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .upload-icon {
    width: 20px;
    height: 20px;
  }

  .upload-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }

  .video-container iframe {
    height: 200px;
  }
}
