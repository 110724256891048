@import url('../variables.css');

.Gallery {
font-family: 'Open Sans', sans-serif;
background-color: var(--background-color);
color: var(--text-color);
padding: 2rem;
max-width: 1200px;
margin: 0 auto;
}

.hero {
text-align: center;
margin-bottom: 3rem;
}

.hero h1 {
    font-family: 'Roboto Slab', serif;
    font-size: 3rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.hero h2 {
    font-family: 'Roboto Slab', serif;
    font-size: 1.5rem;
    color: var(--secondary-color);
} 

.gallery-list {
display: flex;
flex-wrap: wrap;
justify-content: center;
padding: 4rem 2rem;
max-width: 1200px;
margin: 0 auto;
}

.gallery-item {
flex: 0 1 calc(33.333% - 2rem);
margin: 1rem;
display: flex;
justify-content: center;
}

@media (max-width: 1024px) {
.gallery-item {
    flex: 0 1 calc(50% - 2rem);
}
}

@media (max-width: 768px) {
.gallery-list {
    /* flex-direction: column; */
    align-items: center;
}

.gallery-item {
    flex: 0 1 100%;
    margin: 1rem 0;
}

.hero h1 {
    font-size: 2rem;
}

.hero h2 {
    font-size: 1rem;
}
}