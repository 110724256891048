@import url('../variables.css');

.approve-container {
  font-family: 'Open Sans', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.approve-container h1 {
  font-family: 'Roboto Slab', serif;
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.approve-container p {
  font-size: 1.1rem;
  color: var(--secondary-color);
  margin-bottom: 2rem;
  line-height: 1.6;
}

.image-container {
  margin-bottom: 2rem;
}

.image-container img {
  max-width: 100%;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius);
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.approve-button, .reject-button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: var(--border-radius);
  transition: background-color 0.3s;
}

.approve-button {
  background-color: var(--accent-color);
  color: var(--light-text-color);
}

.approve-button:hover {
  background-color: var(--accent-color);
}

.reject-button {
  background-color: var(--secondary-color);
  color: var(--light-text-color);
}

.reject-button:hover {
  background-color: var(--secondary-color);
}

.loading-message {
  font-size: 1.2rem;
  color: var(--secondary-color);
  margin-top: 2rem;
}

@media (max-width: 768px) {
  .approve-container {
    padding: 1rem;
  }

  .approve-container h1 {
    font-size: 2rem;
  }

  .approve-container p {
    font-size: 1rem;
  }

  .approve-button, .reject-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}